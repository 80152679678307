import styled from "styled-components";

export const LoginInputWrapper2 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  margin-bottom: 43px;
  justify-content: flex-start;
  position: relative;
  left: -160px;

  label {
    font-weight: 700;
    font-size: 24px;
    color: var(--creamWhite);
    margin-right: 30px;
    // width: 230px;
  }
`;