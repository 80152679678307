import React, { useState, useCallback } from "react";
import { addYears, format } from "date-fns";
import axios from "axios";
import useFirebase from "../../firebase/useFirebase";
import { generatePassword } from "../utils/password";
import { navigate } from "gatsby";
import { AccountButton } from "../components/Account";
import {
  CustomButton,
  CustomButtonContainer,
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  TextInput,
} from "../components/common";
import {
  LoginArticle,
  LoginArticleForm,
  LoginArticleFormsContainer,
  LoginInfo,
} from "../components/Login";
import { PageHeaderImageSign } from "../components/common/PageHeaderImage";
import { LoginInputWrapper } from "../components/Login/LoginInputWrapper";
import { LoginInputWrapper2 } from "../components/Login/LoginInputWrapper2";
import CustomRadioAndCheckbox from "../components/common/CustomRadioAndCheckbox";
import { useEffect } from "react";

const CREATING_USER_STATUSES = {
  NOT_CREATED: "NOT_CREATED",
  CREATING_USER: "CREATING_USER",
  USER_CREATED: "USER_CREATED",
  FAILED_CREATING_USER: "FAILED_CREATING_USER",
};

const Admin = () => {
  const firebase = useFirebase();
  const [creatingUserStatus, setCreatingUserStatus] = useState(
    CREATING_USER_STATUSES.NOT_CREATED
  );
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState({
    email: "",
    firstName: "",
    lastName: "",
    accountType: "",
  });

  const userInfoInputHandler = (e) => {
    e.persist();
    setCreatingUserStatus(CREATING_USER_STATUSES.NOT_CREATED);
    setUserInfo((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  };

  const sendResetPasswordEmail = useCallback(
    () =>
      firebase.auth().sendPasswordResetEmail(userInfo.email, {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true,
      }),
    [firebase, userInfo.email]
  );

  const createUser = useCallback(
    async (e) => {
      e.preventDefault();

      setInvalidForm(false);

      if (isEmpty(userInfo.accountType) || isEmpty(userInfo.firstName) || isEmpty(userInfo.lastName) || isEmpty(userInfo.accountType) || isEmpty(userInfo.email)) {
        return setInvalidForm(true);
      }

      setCreatingUserStatus(CREATING_USER_STATUSES.CREATING_USER);
      const tempPassword = generatePassword();

      try {
         const userCredential = await firebase
          .auth()
          .createUserWithEmailAndPassword(userInfo.email, tempPassword);
        const user = userCredential.user;
        await user.updateProfile({
          displayName: `${userInfo.firstName} ${userInfo.lastName}`,
        });
        await sendResetPasswordEmail();

        await axios.post(`/.netlify/functions/addSubscriber`, {
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          userUID: user.uid,
          subscriptionType: userInfo.accountType,
          endOfSubscriptionDate: format(addYears(new Date(), 1), "yyyy-MM-dd"),
        });
        setCreatingUserStatus(CREATING_USER_STATUSES.USER_CREATED);
      } catch (error) {
        // TODO: handle error
        setCreatingUserStatus(CREATING_USER_STATUSES.FAILED_CREATING_USER);
        setError(error.code);
        return null;
      }
      setUserInfo({
        email: "",
        firstName: "",
        lastName: "",
        accountType: "",
      });
    },
    [
      firebase,
      userInfo.email,
      userInfo.firstName,
      userInfo.lastName,
      userInfo.accountType,
      sendResetPasswordEmail,
    ]
  );

  const logoutHandler = () => {
    document.cookie =
      "gatsby-theme-password-protect=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  const isEmpty = (value) => value.trim() === "";

  useEffect(() => {
    let enteredFirstName = !isEmpty(userInfo.firstName);
    let enteredLastName = !isEmpty(userInfo.lastName);
    let enteredAccountType = !isEmpty(userInfo.accountType);
    let enteredEmail = !isEmpty(userInfo.email);
    let formIsValid = enteredFirstName && enteredLastName && enteredAccountType && enteredEmail;
    setDisabledSubmitButton(formIsValid);
  }, [userInfo]);

  return (
    <PageSectionWrapper>
      <PageSection bgColor="var(--loginBg)">
        <PageSectionBackdrop bgColor="var(--loginBg)">
          <LoginArticle>
            <LoginArticleFormsContainer>
              <PageHeaderImageSign />
              <h1>Skapa användare</h1>

              <LoginArticleForm onSubmit={createUser} marginBottom="50px">
                <LoginInputWrapper2>
                {/* <div className="nyaprodukter"> */}
                  <label htmlFor="accountTypeSchool">Lärarwebb + 30 elever</label>
                  <CustomRadioAndCheckbox
                    onChange={userInfoInputHandler}
                    type="radio"
                    name="accountType"
                    value="school"
                    required
                  />
                  <label htmlFor="accountTypeSchool20">Lärarwebb + 20 elever</label>
                  <CustomRadioAndCheckbox
                    onChange={userInfoInputHandler}
                    type="radio"
                    name="accountType"
                    value="school20"
                    required
                  />
                  <label htmlFor="accountTypePedagog">Lärarwebb + 7 elever</label>
                  <CustomRadioAndCheckbox
                    onChange={userInfoInputHandler}
                    type="radio"
                    name="accountType"
                    value="pedagog"
                    required
                  />
                  <label htmlFor="accountTypePrivate">Lärarwebb + 1 elev</label>
                  <CustomRadioAndCheckbox
                    onChange={userInfoInputHandler}
                    type="radio"
                    name="accountType"
                    value="private"
                    required
                  />
                  <label htmlFor="accountTypeLararwebb">Lärarwebb</label>
                  <CustomRadioAndCheckbox
                    onChange={userInfoInputHandler}
                    type="radio"
                    name="accountType"
                    value="lararwebb"
                    required
                  />
                  <label htmlFor="accountTypeParent">Förälder + barn</label>
                  <CustomRadioAndCheckbox
                    onChange={userInfoInputHandler}
                    type="radio"
                    name="accountType"
                    value="parent"
                    required
                  />
                  {/* </div> */}
                </LoginInputWrapper2>

                <LoginInputWrapper>
                  <label htmlFor="firstName">Förnamn</label>
                  <TextInput
                    name="firstName"
                    type="text"
                    value={userInfo.firstName}
                    onChange={userInfoInputHandler}
                    customWidth="325px"
                  />
                </LoginInputWrapper>
                <LoginInputWrapper>
                  <label htmlFor="lastName">Efternamn</label>
                  <TextInput
                    name="lastName"
                    type="text"
                    value={userInfo.lastName}
                    onChange={userInfoInputHandler}
                    customWidth="325px"
                  />
                </LoginInputWrapper>
                <LoginInputWrapper>
                  <label htmlFor="email">E-post</label>
                  <TextInput
                    name="email"
                    type="email"
                    value={userInfo.email}
                    onChange={userInfoInputHandler}
                    customWidth="325px"
                  />
                </LoginInputWrapper>
                <CustomButtonContainer width="325px" style={{flexDirection: 'column'}}>
                  { invalidForm && <p style={{color: '#ff0000'}}>Du måste fylla i alla fält!</p> }
                  <CustomButton disabled={!disabledSubmitButton} type="submit">
                    Skicka
                  </CustomButton>
                </CustomButtonContainer>
              </LoginArticleForm>
              {creatingUserStatus === CREATING_USER_STATUSES.CREATING_USER && (
                <LoginInfo>
                  <p>Skapar användare ...</p>
                </LoginInfo>
              )}
              {creatingUserStatus === CREATING_USER_STATUSES.USER_CREATED && (
                <LoginInfo>
                  <p>
                    Ett mail har skickats med en länk där användaren får välja
                    lösenord.
                  </p>
                </LoginInfo>
              )}
              {creatingUserStatus ===
                CREATING_USER_STATUSES.FAILED_CREATING_USER &&
                (error === "auth/email-already-in-use" ? (
                  <LoginInfo>
                    <p>
                      Användarens e-post finns redan. Kontakta användaren om en
                      ny e-postadress
                    </p>
                  </LoginInfo>
                ) : (
                  <LoginInfo>
                    <p>
                      Något har gått snett men en användare bör ha skapats inom
                      4 timmar.
                    </p>
                  </LoginInfo>
                ))}
              <CustomButtonContainer width="325px">
                <AccountButton
                  btnColor="white"
                  type="button"
                  onClick={logoutHandler}
                >
                  Logga ut
                </AccountButton>
              </CustomButtonContainer>
            </LoginArticleFormsContainer>
          </LoginArticle>
        </PageSectionBackdrop>
      </PageSection>
    </PageSectionWrapper>
  );
};

export default Admin;
